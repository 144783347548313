<template lang="">
    <div>
       <FormView @submit="previewLesson">
            <div class="row">
                <div class="col-lg-12">
                    <div :class="`alert alert-${msgType}`" role="alert" v-if="msg">
                       {{msg}}
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="group-frm">
                        <InlineInput
                            :label="$t('lesson.form.title')"
                            name="title"
                            rules="required|max:40"
                            v-model="data.title"
                            :placeholder="$t('lesson.form.title_placeholder')"
                        />
                    </div>
                    <div class="group-frm">
                        <InlineTextArea 
                            :label="$t('lesson.form.introduction')"
                            name="introduction"
                            required
                            v-model="data.introduction"
                            :placeholder="$t('lesson.form.intro_placeholder')"
                        />
                    </div>
                    <div class="group-frm">
                        <InlineInput
                            type="number"
                            :label="$t('lesson.form.price')"
                            name="price"
                            rules="required|min_value:100" 
                            placeholder="￥"
                            :value="data.price"
                            @input="val => val < 0 ? data.price = 0 : data.price = val"
                            minValue="0"
                        />
                        <div class="row">
                            <div class="col-md-3 frm-l"></div>
                            <div class="col-md-9 frm-r">
                                <label>{{$t('lesson.form.price_note')}}</label>
                            </div>
                        </div>
                    </div>
                     <div class="group-frm">
                        <LessonOptionalItems 
                            @change="handleOptionalItems"
                            :items="data.optionalItems"
                        />
                    </div>
                    <div class="group-frm">
                        <UploadImageInline
                            :label="$t('lesson.form.thumbnail')"
                            subLabel="(推奨画像サイズ:240x240px)"
                            name="thumbnail"
                            :url="preview.thumbnail"
                            required
                            @change="(e) => onFileChange(e, 'thumbnail')"
                        />
                    </div>
                    <div class="group-frm">
                        <UploadImageInline
                            :label="$t('lesson.form.image_1')"
                            subLabel="(推奨画像サイズ:720x380px)"
                            name="image_1"
                            :url="preview.image_1"
                            @change="(e) => onFileChange(e, 'image_1')"
                        />
                    </div>
                    <div class="group-frm">
                        <UploadImageInline
                            :label="$t('lesson.form.image_2')"
                            subLabel="(推奨画像サイズ:720x380px)"
                            name="image_2"
                            :url="preview.image_2"
                            @change="(e) => onFileChange(e, 'image_2')"
                        />
                    </div>
                    <div class="group-frm">
                        <SelectInline 
                            :label="$t('lesson.form.category')"
                            name="category"
                            :options="categories.data"
                            v-model="data.category_id"
                            required
                            @change="handleCategory"
                        />
                    </div>
                    <div class="group-frm">
                        <SelectInline 
                            :label="$t('lesson.form.child_category')"
                            name="child_category"
                            :options="child_categories"
                            v-model="data.sub_category_id"
                            required
                            @change="handleChildCategory"
                        />
                    </div>
                    <div class="group-frm">
                        <SelectInline 
                            :label="$t('lesson.form.level')"
                            name="level"
                            :options="levelOptions"
                            v-model="data.level"
                            @change="handleLevel"
                        />
                    </div>
                    <div class="group-frm">
                        <SelectInline 
                            label="受付枠数"
                            name="number_of_slots"
                            :options="optionNumberOfSlots"
                            v-model="data.number_of_slots"
                            required
                            @change="onChangeNoOfSlots"
                        />
                        <div class="row">
                            <div class="col-md-3 frm-l">
                            </div>
                            <div class="col-md-9 frm-r">
                                <input type="checkbox" id="auto_sale_available_slots" v-model="data.auto_sale_available_slots" :checked="data.auto_sale_available_slots"/>
                                <label for="auto_sale_available_slots"> 枠に空きができたときに自動的に販売中に変更する</label>
                            </div>
                        </div>
                    </div>
                    <div class="group-frm">
                        <RadioInline
                            :label="$t('lesson.form.status')"
                            name="status"
                            :options="statusOptions"
                            v-model="data.status"
                            required
                        />
                    </div>
                    <div class="group-frm text-center">
                        <div class="w-100 text-center">
                            <router-link to="/term-of-service" target="_blank">利用規約</router-link> |
                            <router-link to="/handling-pi"  target="_blank"
                            >個人情報の取り扱いについて</router-link
                            >
                        </div>
                        <div class="w-100 text-center d-flex">
                            <router-link to="/my-page/lesson"  class="btn btn-cancel" >
                                キャンセル
                            </router-link> 
                            <button 
                                type="submit" 
                                class="btn btn-submit" 
                                :disabled="isSaving"
                            >
                                {{  isSaving 
                                    ? $t("lesson.form.creating") 
                                    : $t("lesson.form.create") 
                                }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
       </FormView>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import LessonOptionalItems from '@/modules/pc/mypage/lesson/components/lesson-optional-items.vue';

export default {
    components: {
        LessonOptionalItems
    },
    data() {
        return {
            preview: {
                thumbnail: "",
                image_1: "",
                image_2: "",
            },
            data: {
                member_id: "",
                title: "",
                introduction: "",
                level: "beginner",
                price: "",
                thumbnail: "",
                image_1: "",
                image_2: "",
                category_id: "",
                sub_category_id: "",
                status: "active",
                optionalItems: [],
                number_of_slots: 1,
                auto_sale_available_slots: true,
            },
            statusOptions: [
                { value: "active", name: this.$t("lesson.form.available") },
                { value: "inactive", name: this.$t("lesson.form.no_available") }
            ],
            levelOptions: [
                { id: "beginner", name: this.$t("lesson.form.beginner") },
                { id: "intermediate", name: this.$t("lesson.form.intermediate") },
                { id: "advanced", name: this.$t("lesson.form.advanced") }
            ],
            child_categories: [],
            isSaving: false,
            msgType: "danger",
            msg: ""
        }
    },
    computed: {
        ...mapGetters({
            categories: "lessonCategory/getCategoryMenu",
        }),
        optionNumberOfSlots() {
            let ops = [];
            for (let no = 1; no <= 99; no++) {
                ops.push({ id: no, name: `${no}件` });
            }
            return ops;
        },
    },
    async created() {
        await this.fetchCategoryMenus();
        if (this.$route.query.previewId) {
            this.loadPreview();
        }
    },
    methods: {
        ...mapActions({
            fetchCategoryMenus: "lessonCategory/fetchCategoryMenus",
            storePreviewLesson: "lesson/storePreviewLesson",
            getPreviewLesson: "lesson/getPreviewLesson",
            createLesson: "lesson/createLesson",
        }),
        handleLevel(val){
            this.data.level = val;
        },
        handleCategory(id) {
            this.data.category_id = id;
            let category = this.categories.data.find(o => o.id == id);
            if (category) {
                this.data.sub_category_id = null;
                this.child_categories = category.children;
            }
        },
        handleChildCategory(id) {
            this.data.sub_category_id = id;
        },
        onFileChange({ url, path }, name) {
            this.preview[name] = url;
            this.data[name] = path;
        },
        previewLesson() {

            if (this.data.status == 'inactive') {
                this.handleUpdateStatus();
                return;
            }
            if (this.isSaving) return;
            const items = this.data.optionalItems;
            let invalid = false;
            for (let index = 0; index < items.length; index++) {
                const item = items[index];
                if (!item.name) {
                    invalid = true;
                    this.msg = this.$t("lesson.form.option_name_required");
                    break;
                } else if (item.price < 100) {
                    invalid = true;
                    this.msg = this.$t("lesson.form.price_required");
                    break;
                }
            }
            if (invalid) {
                window.scrollTo({ top: 0, behavior: 'smooth' });
                return;
            }
            this.storePreviewLesson({
                data: {
                    ...this.data,
                    member_id: this.getMemberId(),
                    action_type: "create"
                },
                cb: (response) => {
                    if (response.data &&
                        response.meta &&
                        response.meta.code == 200) {
                        this.$router.push(`/my-page/lesson-preview/${response.data.id}`);
                    } else {
                        if (response.data && response.data.errors) {
                            let { errors } = response.data;
                            let _error = Object.values(errors)[0];
                            if (typeof _error == "object") _error = _error[0]
                            this.msg = _error;
                        } else if (response.data && response.data.message) {
                            this.msg = response.data.message.substr(0, 100);
                        } else {
                            this.msg = this.$t("lesson.created_failed");
                        }
                        this.msgType = "danger"
                    }
                    this.isSaving = false;
                    this.$isLoading(false);
                }
            })
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },
        handleOptionalItems(items) {
            this.data.optionalItems = items;
        },
        getParentByChildCategory(id) {
            let category = this.categories.data.find(o => o.id == id);
            if (!category) {
                for (let obj of this.categories.data) {
                    let child = obj.children.find(o => o.id == id);
                    if (child) {
                        this.child_categories = obj.children;
                        this.data.category_id = obj.id;
                        break;
                    }
                }
                this.data.sub_category_id = id;
            } else {
                this.handleCategory(id);
            }
        },
        handleUpdateStatus() {
            if (this.isSaving) return;
            const payload = this.data;
            const items = payload.optionalItems;
            let invalid = false;
            for (let index = 0; index < items.length; index++) {
                const item = items[index];
                if (!item.name) {
                    invalid = true;
                    this.msg = this.$t("lesson.form.option_name_required");
                    break;
                } else if (item.price < 100) {
                    invalid = true;
                    this.msg = this.$t("lesson.form.price_required");
                    break;
                }
            }
            if (invalid) {
                return;
            }

            this.isSaving = true;
            this.$isLoading(true);
            this.createLesson({
                data: payload,
                cb: (response) => {
                    if (response.data &&
                        response.meta &&
                        response.meta.code == 200) {
                        this.msg = this.$t("lesson.created_success");
                        this.msgType = "success";
                        setTimeout(() => {
                            this.$router.push("/my-page/lesson");
                        }, 1000);
                    } else {
                        if (response.data && response.data.errors) {
                            let { errors } = response.data;
                            let _error = Object.values(errors)[0];
                            if (typeof _error == "object") _error = _error[0]
                            this.msg = _error;
                        } else if (response.data && response.data.message) {
                            this.msg = response.data.message.substring(0, 100);
                        } else {
                            this.msg = this.$t("lesson.created_failed");
                        }
                        this.msgType = "danger"
                    }
                    this.isSaving = false;
                    this.$isLoading(false);
                }
            })
        },
        loadPreview() {
            this.getPreviewLesson({
                params: {
                    id: this.$route.query.previewId
                },
                cb: (response) => {
                    this.data = response.data.payload;
                    this.preview = response.data.preview;
                    this.getParentByChildCategory(this.data.sub_category_id);
                }
            })
        },
        onChangeNoOfSlots(val) {
            this.data.number_of_slots = val;
        }
    },
}
</script>